import * as React from "react"
import styled from 'styled-components';
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

//Components
import ProjectInfo from '../../components/ProjectInfo';
import ProjectHeader from '../../components/ProjectHeader';
import ProjectCaseStudy from '../../components/ProjectCaseStudy';
import ProjectSubHeader from "../../components/ProjectSubHeader";
import FooterWithPadding from '../../components/FooterWithPadding';

const MarksHubPage = () => {

  const data = useStaticQuery(graphql`
    query {

      markshubOne: file(relativePath: { eq: "markshub/markshub-one.png" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      markshubTwo: file(relativePath: { eq: "markshub/markshub-two.png" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      markshubThree: file(relativePath: { eq: "markshub/markshub-three.png" }) {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }



      
    }
  `)



  return (
    <Grid>

      <ProjectHeader 
        title="UL - Marks Hub"
        subTitle="Redesigning the standard tool in global safety certifications."
      />
      <ProjectInfo 
        when="Late 2018 - Early 2019"
        platform="Web (responsive)"
        team="2 backend devs, 1 PM, 1 designer/front end dev (me)"
        role="Lead Designer & Front End Dev"
        tools="Sketch, Figma, Invision, HAML, SASS, Ruby on Rails, Git, VSCode, Pixelmator, Principle, Jira"

        symbolPlatform="💻"
      />

      <ProjectCaseStudy 
        company="UL LLC"
        companyInfo="is a global safety certification company headquartered in Northbrook, Illinois. It maintains offices in 46 countries. Underwriters Labratories is the largest and best known independent, not-for-profit testing labratory in the world. UL conducts safety and quality tests on a broad range of products, from fire doors to CCTV cameras."

        backgroundTitle="Background"
        backgroundText="I was brought onto the MarksHub project while working at 8bit studios. MarksHub 
        is Underwriters Labratories B2B application/division that allows clients to create and maintain 
        their industry-known safety badges, (you've probably seen them on the underside of some of your electronics). 
        I worked as the Lead Designer and Front End Developer, working hands-on with a Project Manager and two 
        Backend Devs. I also kept weekly communication and gave project updates & presentations to our contacts 
        at the MarksHub corporate office."

        problemTitle="Problem"
        problemText="The MarksHub site had not been updated in years. It was outdated and clunky, with 
        responsiveness broken across the site. There were also many broken links and pages leading to 
        customers being confused, not understanding certain parts of the workflow to create a safety badge. 
        The time and budget allotted allowed us to redesign the UX for building safety badges along with 
        the main landing/marketing page. The rest of the work was to take the existing site and reskin 
        the UI to be responsive, secure, and modern looking. I also applied many interactions to the 
        landing page and build workflow to make the states clearer and functional. This was achieved 
        by hover interactions, animations, and clearer navigation/states while the customer is 
        in the build process."

        outcomeTitle="Outcome"
        outcomeText="I researched and presented my questions, discoveries and initial design mocks to 
        the Lead PM and VP of Design at 8bit. After that, I was given the green light to present the 
        proposed solution to the MarksHub team. This was a collaborative process that lasted a couple 
        of weeks. After achieving consensus on the new designs and workflow I worked in an agile 
        environment implementing the new designs into the front end of the site. We successfully 
        launched into production before the end of the new year. After that, I continued 
        participating in weekly meetings and working on the site for a couple of months, doing 
        various maintenance on the front end as needed.
        "
      />

      <ProjectSubHeader 
        title="Design Preview"
        button="View Full Designs"
        href="https://www.figma.com/file/MHaafpw18hFvbeZwNSBWIs/nd-portfolio-markshub?node-id=0%3A1"
      />
      <ProjectShowcaseCard>
        <Images fluid={data.markshubOne.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.markshubTwo.childImageSharp.fluid} />
      </ProjectShowcaseCard>
      <ProjectShowcaseCard>
        <Images fluid={data.markshubThree.childImageSharp.fluid} />
      </ProjectShowcaseCard>
    <FooterWithPadding />
    </Grid>
  )
}

export default MarksHubPage




const Grid = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  max-width: 1110px;
  margin: 0 auto;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const ProjectShowcaseCard = styled.div`
  /* background: #F6F6F6;
  border-radius: 1rem; */
  padding: 48px;
  margin-top: 24px;
  border: .1rem solid #c7c7cc;
  box-shadow: 0 0.2rem 0.6rem 0 rgba(37,37,37,0.1);
  border-radius: 1rem;
  background: rgba(221, 221, 221, 0.11);

  @media (max-width: 900px) {
    padding: 24px;
  }
`

const Images = styled(Img)`
  max-width: 960px;
  margin: 0 auto;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.16));
  /* border: 1px solid #D7D6D7;
  border-radius: 10px; */
`
